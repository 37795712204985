/** @jsx jsx */
import { jsx, Grid, Box, Flex, Heading, Text } from 'theme-ui'
import React, { useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import {
  compose,
  sortBy,
  path,
  pathSatisfies,
  splitWhen,
  isEmpty,
  complement,
  splitAt,
} from 'ramda'

import { SEO } from 'components'

const notEmpty = complement(isEmpty)

function CaptionedImage(props) {
  const { image } = props

  return (
    <Box sx={{ display: 'grid' }}>
      <Image
        fluid={image.fluid}
        sx={{
          objectFit: 'cover',
          display: 'grid',
        }}
      />
      {notEmpty(image.description) && (
        <Text variant="stnd.sub" sx={{ color: 'black.60', paddingTop: 'xs' }}>
          {image.description}
        </Text>
      )}
    </Box>
  )
}

function GalleryPage() {
  const data = useStaticQuery(graphql`
    query contentfulGallery {
      gallery: contentfulGallery(title: { eq: "Gallery" }) {
        id
        images {
          id
          description
          fluid(maxWidth: 1280) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)

  const images = data?.gallery?.images

  const getSortedImages = compose(
    splitWhen(pathSatisfies((y) => y > 1, ['fluid', 'aspectRatio'])),
    sortBy(path(['fluid', 'aspectRatio']))
  )

  const [portraitImages, landscapeImages] = getSortedImages(images)

  const [firstHalf, secondHalf] = splitAt(3, landscapeImages)

  return (
    <>
      <SEO title="Gallery" />

      <Box sx={{ paddingX: 'm', paddingY: ['l', 'xl', 'xxl', 'xxxl'] }}>
        <Heading
          variant="heading.xl"
          sx={{
            color: 'black.100',
            mb: ['xl', 'xxl'],
            maxWidth: 'figure',
            marginX: 'auto',
            textAlign: 'center',
          }}
        >
          Gallery
        </Heading>
        <Grid
          sx={{ maxWidth: 'figure', marginX: 'auto' }}
          gap={['m', null, 'l']}
        >
          {firstHalf.map((image, key) => (
            <CaptionedImage image={image} key={key} />
          ))}

          <Grid columns={[1, null, 3]} gap={['m', null, 'l']}>
            {portraitImages.map((image, key) => (
              <CaptionedImage image={image} key={key} />
            ))}
          </Grid>

          {secondHalf.map((image, key) => (
            <CaptionedImage image={image} key={key} />
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default GalleryPage
